import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

function NavBar() {
    return (
        <Fade>
            <div className="nav_bar">
                <Link to="/">#</Link>
                <Link to="/kimdir">kimdir?</Link>
                <Link to="etkinlikler">etkinlikler</Link>
                {/* <Link to="/video">videolar</Link> */}
            </div>
        </Fade>
    );
}

export default NavBar;
