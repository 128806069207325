import React from "react";
import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png";
import instagram from "../images/instagram.png";
import youtube from "../images/youtube.png";
import podcast from "../images/podcast.png";
import spotify from "../images/spotify.png";
import Fade from "react-reveal/Fade";

function Social() {
    return (
        <Fade>
            <div className="social">
                <a
                    href="https://www.youtube.com/c/%C5%9EafakSalda/videos"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={youtube} alt=".." className="social_img"></img>
                </a>
                <a
                    href="https://twitter.com/shafaksalda"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={twitter} alt=".." className="social_img"></img>
                </a>
                <a
                    href="https://www.instagram.com/safaksalda"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={instagram} alt=".." className="social_img"></img>
                </a>
                <a
                    href="https://www.facebook.com/sasalda"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={facebook} alt=".." className="social_img"></img>
                </a>
                <a
                    href="https://berlinkafasi.fireside.fm/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={podcast} alt=".." className="social_img"></img>
                </a>
                <a
                    href="https://open.spotify.com/show/6AIQCZC8zmJXfs7a3CzZ26"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={spotify} alt=".." className="social_img"></img>
                </a>
            </div>
        </Fade>
    );
}

export default Social;
