import React from "react";

function Footer() {
    return (
        <div className="footer">
            <a
                href="www.candaserdener.info"
                target="_blank"
                referrerPolicy="no-referrer"
            >
                © 2020 Candas Erdener
            </a>
        </div>
    );
}

export default Footer;
