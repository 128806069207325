import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Loader from "react-loader-spinner";

function Kimdir() {
    const [kimdir, setKimdir] = useState();
    const [loading, setLoading] = useState(true);
    const [errorLoad, setErrorLoad] = useState(false);

    useEffect(() => {
        axios
            .get("https://sfkbckend.herokuapp.com/kimdirs")
            .then((response) => {
                console.log(response);

                setKimdir(response.data[0].text);
                setLoading(false);
            })
            .catch((err) => {
                setErrorLoad(true);
                setLoading(false);
                // console.log("loading, error", loading, errorLoad);
                console.log("error:", err);
            });
    }, [loading, errorLoad]);

    return (
        <Fade>
            <div>
                {loading && !errorLoad && (
                    <Loader
                        type="TailSpin"
                        color="#f6eeee"
                        height={50}
                        width={50}
                        // timeout={3000} //3 secs
                    />
                )}
                {errorLoad && (
                    <h2>Birşeyler ters gitti, lütfen tekrar deneyin.</h2>
                )}
                {!loading && !errorLoad && (
                    <div className="kimdir">
                        <p>{kimdir}</p>
                    </div>
                )}
            </div>
        </Fade>
    );
}

export default Kimdir;
