import React, { useEffect, useState } from "react";
import "./App.css";
import Footer from "../src/components/footer";
import NavBar from "../src/components/navbar";
import Tweets from "../src/components/tweets";
import Social from "../src/components/social";
// import Video from "../src/components/video";
import Kimdir from "../src/components/kimdir";
import Etkinlikler from "../src/components/etkinlikler";
import { Route } from "react-router-dom";
// import Fade from "react-reveal/Fade";
import axios from "axios";
// import Loader from "react-loader-spinner";
// import Background from "../src/images/safakhq.jpg";

function App() {
  // const [loading, setLoading] = useState(true);
  const [errorLoad, setErrorLoad] = useState(false);

  useEffect(() => {
    axios
      .get("https://sfkbckend.herokuapp.com/kimdirs")
      .then((response) => {
        // console.log(response);
        // setLoading(false);
      })
      .catch((err) => {
        setErrorLoad(true);
        // setLoading(false);
        // console.log("loading, error", loading, errorLoad);
        console.log("error:", err);
      });
  }, [errorLoad]);
  return (
    // <Router>
    <div className="App">
      {/* <img src={Background} alt="" className="background"></img> */}
      <div className="up">
        <NavBar></NavBar>
        <Social></Social>
      </div>
      <Route exact path="/">
        <div>
          {/* {loading && !errorLoad && (
            <Loader
              type="TailSpin"
              color="#f6eeee"
              height={50}
              width={50}
              // timeout={3000} //3 secs
            />
          )} */}
        </div>
      </Route>
      <div className="main">
        <Route exact path="/">
          {/* {!loading && !errorLoad && <Tweets></Tweets>} */}
          <Tweets></Tweets>
        </Route>
        {/* <Route exact path="/video">
                        <Video></Video>
                    </Route> */}
        <Route exact path="/kimdir">
          <Kimdir></Kimdir>
        </Route>
        <Route exact path="/etkinlikler">
          <Etkinlikler></Etkinlikler>
        </Route>
      </div>

      <Footer></Footer>
    </div>
    // </Router>
  );
}

export default App;
