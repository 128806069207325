import React, { useEffect, useState } from "react";
// import Query from "../components/Query";
// import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import Fade from "react-reveal/Fade";

// import CATEGORIES_QUERY from "../queries/category/categories";

const Etkinlikler = () => {
    const [etkinlikGlc, setEtkinlikGlc] = useState([]);
    const [etkinlikGcm, setEtkinlikGcm] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorLoad, setErrorLoad] = useState(false);

    useEffect(() => {
        axios
            .get("https://sfkbckend.herokuapp.com/etkinliklers")
            .then((response) => {
                // console.log(response.data);
                // let tarih = Date(response.data[0].tarih);
                let today = Date.now();
                let tempEtkGlc = [];
                let tempEtkGcm = [];
                // console.log("tarih:", tarih);
                // console.log("bugun:", bugun);
                // console.log(tarih > bugun);
                response.data.map((each) => {
                    let tempObj = each;
                    let etkDate = new Date(each.tarih);
                    if (etkDate > today) {
                        // console.log(each.tarih);
                        tempObj.v_tarih = etkDate.toLocaleString("de-De", {
                            timeZone: "Europe/Berlin",
                        });
                        tempObj.v_tarih = tempObj.v_tarih.slice(0, -3);
                        tempEtkGlc.push(tempObj);
                    } else {
                        // console.log(each.tarih);
                        tempObj.v_tarih = etkDate.toLocaleString("de-De", {
                            timeZone: "Europe/Berlin",
                        });
                        tempObj.v_tarih = tempObj.v_tarih.slice(0, -3);
                        tempEtkGcm.push(tempObj);
                    }

                    // console.log("tempEtkGlc:", tempEtkGlc);
                    // console.log("tempEtkGcm:", tempEtkGcm);
                    return tempEtkGlc;
                });
                tempEtkGcm.sort((a, b) => (a.tarih < b.tarih ? 1 : -1));
                tempEtkGlc.sort((a, b) => (a.tarih > b.tarih ? 1 : -1));

                setEtkinlikGcm(tempEtkGcm);
                setEtkinlikGlc(tempEtkGlc);
                // setEtkinlik(response.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorLoad(true);
                setLoading(false);
                console.log("error:", err);
            });
    }, [loading]);

    return (
        <Fade>
            <div>
                {errorLoad && (
                    <h2>Birşeyler ters gitti, lütfen tekrar deneyin.</h2>
                )}
                {loading && (
                    <Loader
                        type="TailSpin"
                        color="#f6eeee"
                        height={50}
                        width={50}
                        // timeout={3000} //3 secs
                    />
                )}
                <div className="etkinlikler">
                    {etkinlikGlc.length > 0 && !loading && !errorLoad && (
                        <div className="gelecek">
                            <h3>Gelecek Etkinlikler</h3>

                            {etkinlikGlc.map((each, i) => {
                                return (
                                    <div className="etkinlik-gelecek" key={i}>
                                        <a
                                            href={each.etkinlik_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={each.foto.url} alt=".." />
                                        </a>
                                        <div className="gelecek_text">
                                            <a
                                                href={each.etkinlik_link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <h2>{each.ad}</h2>
                                            </a>

                                            <h3>{each.v_tarih}</h3>
                                            <a
                                                href={each.mekan_link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <h3>@ {each.mekan}</h3>
                                            </a>

                                            <p>{each.tanim}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!loading && !errorLoad && (
                        <div>
                            {" "}
                            <h3>Geçmiş etkinlikler</h3>
                            <div className="gecmis">
                                {etkinlikGcm.map((each, i) => {
                                    return (
                                        <div
                                            className="etkinlik-gecmis"
                                            key={i}
                                        >
                                            <a
                                                href={each.etkinlik_link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={each.foto.url}
                                                    alt=".."
                                                />
                                            </a>

                                            <div className="gecmis-text">
                                                <h4>{each.ad} </h4>
                                                <h4> {each.v_tarih}</h4>
                                                <a
                                                    href={each.mekan_link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <h4 className="gecmis-text">
                                                        @ {each.mekan}
                                                    </h4>
                                                </a>
                                            </div>
                                            {/* <h4>{each.tarih}</h4> */}

                                            <p>{each.tanim}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fade>
    );
};

export default Etkinlikler;

//  <Query query={CATEGORIES_QUERY} id={null}>
//             {({ data: { categories } }) => {
//                 return (
//                     <div>
//                         {categories.map((category, i) => {
//                             return (
//                                 <li key={category.id}>
//                                     <Link
//                                         to={`/category/${category.id}`}
//                                         className="uk-link-reset"
//                                     >
//                                         {category.name}
//                                     </Link>
//                                 </li>
//                             );
//                         })}
//                     </div>
//                 );
//             }}
//         </Query>
