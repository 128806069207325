import React, { useState, useEffect, useRef } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { isSafari } from "react-device-detect";
// import Fade from "react-reveal/Fade";

function Tweets() {
    const [windowWidth, setWindowWidth] = useState();
    // const [windowHeight, setWindowHeight] = useState();
    const [tweetWidth, setTweetWidth] = useState("");
    const [tweetHeight, setTweetHeight] = useState("");
    const [safari, setSafari] = useState(false);
    const twitter_widget = useRef();

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        const twit = twitter_widget.current;

        if (isSafari) {
            setSafari(true);
            // console.log(safari);
        }
        if (safari) {
            // console.log(twit);
            twit.className = "tweets-safari";
        }
        if (windowWidth > 1025) {
            setTweetHeight("60vh");
            setTweetWidth("30vw");
        } else {
            setTweetHeight("50vh");
            setTweetWidth("80vw");
        }

        // console.log("twitter:", twitter_widget);
    }, [windowWidth, tweetHeight, tweetWidth, safari]);

    return (
        <div className="tweets">
            {!safari && (
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="shafaksalda"
                    theme="dark"
                    // autoHeight="true"
                    noHeader="true"
                    noFooter="true"
                    // options={{ tweetOptions }}
                    options={{
                        height: `${tweetHeight}`,
                        width: `${tweetWidth}`,
                    }}
                    // options={{ height: "60vh", width: "30vw" }}
                />
            )}
            {safari && (
                <div ref={twitter_widget}>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="shafaksalda"
                        theme="dark"
                        // autoHeight="true"
                        noHeader="true"
                        noFooter="true"
                        // options={{ tweetOptions }}
                        options={{
                            height: 600,
                            width: 400,
                        }}

                        // options={{ height: "60vh", width: "30vw" }}
                    />
                </div>
            )}
        </div>
    );
}

export default Tweets;
